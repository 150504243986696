exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-obsluga-zgloszenie-szkody-js": () => import("./../../../src/pages/obsluga/zgloszenie-szkody.js" /* webpackChunkName: "component---src-pages-obsluga-zgloszenie-szkody-js" */),
  "component---src-pages-wyszukiwarka-js": () => import("./../../../src/pages/wyszukiwarka.js" /* webpackChunkName: "component---src-pages-wyszukiwarka-js" */),
  "component---src-templates-cms-page-category-tsx": () => import("./../../../src/templates/cms-page-category.tsx" /* webpackChunkName: "component---src-templates-cms-page-category-tsx" */),
  "component---src-templates-cms-page-tsx": () => import("./../../../src/templates/cms-page.tsx" /* webpackChunkName: "component---src-templates-cms-page-tsx" */),
  "component---src-templates-damage-report-page-tsx": () => import("./../../../src/templates/damage-report-page.tsx" /* webpackChunkName: "component---src-templates-damage-report-page-tsx" */)
}

